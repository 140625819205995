.login-page {
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-image:url("../../assets/Swirls.png") ;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-position-y: 330px;

  .login-card {
    justify-content: center;
    .logo-box {
      padding: 50px 0 10px 0;
    }
  }

  .header-text-box {
    padding: 10px 0 20px 0;
    h1 {
      text-align: center;
      line-height: 1.2;
      font-weight: 700;
      font-style: normal;
      font-weight: 600;
      font-size: 60px;
      // font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif !important;
      color: #ffffff;
      line-height: 55px;
      // sup {
      //   font-size: 25px;
      //   // border: 2px solid #ffffff;
      //   // border-radius: 50%;
      //   // padding: 5px;
      // }

      sup{
        font-size: 60px;
        top: 22px;
        position: relative;
    }

   

      @media (min-width: 768px) and (max-width: 1023px) {
        font-size: 40px;
        line-height: 35px;
        // sup{
        //   font-size: 20px;
        // }

        sup{
          font-size: 40px;
          top: 14px;
          position: relative;
      }
      }
      @media (max-width: 767px) {
        font-size: 25px;
        line-height: 30px;
        // sup{
        //   font-size: 15px;
        // }
        sup{
          font-size: 25px;
          top: 9px;
          position: relative;
      }
      }  
    }
    h6 {
      text-align: center;
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 400;
      font-size: 35px;
      color: #ffffff;
      line-height: 60px;
      // margin-top: 30px;
      // margin-bottom: -50px !important;
      @media (min-width: 768px) and (max-width: 1023px) {
        font-size: 35px;
        line-height: 40px;
        // margin-top: 25px;
      }
      @media (max-width: 767px) {
        font-size: 25px;
        line-height: 30px;
        // margin-top: 20px;
      }
    }
  }

  .login_outer {
    width: 600px;
    max-width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;

    @media screen and (min-width: 568px) {
      // min-width:700px;
      // padding: 30px 19px;
    }
    @media screen and (min-width: 768px) {
      min-width: 650px;
      // padding: 30px 19px;
    }
  }
  .login-form-box {

    input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
      -webkit-background-clip: text;
      // -webkit-text-fill-color: #ffffff;
      -webkit-text-size-adjust: 0.75rem;
      // -webkit-box-shadow:0 0 0 50px transparent inset;
      // -webkit-backface-visibility: hidden;
    }

    box-shadow: 0 0 100px -20px rgba(0,0,0,.25);
    padding: 80px;
    // background: #ffffff;
    background: #F6F6F6;
    color: #263238;
    border-radius: 12px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 568px) {
      padding: 80px 40px;
    }

    .title {
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 37px;
    }

    .subtitle {
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 26px;
      color: #263238;
    }
    .avtar-container {
      margin: 30px 0px;
      display: flex;
      .avtar-container-warp {
        display: flex;
        align-items: center;
        .dr-name {
          font-size: 11px;
          color: #9b9b9b;
          font-weight: 600;
          line-height: 13.75px;
          padding-left: 30px;
        }
      }
      .avtar-message {
        background-color: rgba(0, 198, 192, 0.09);
        padding: 10px 50px;
        border-radius: 15px;
        color: #000000;
        margin-left: 10px;
        font-size: 18px;
        width: 100%;
      }
    }
    .form-fields {
      margin-top: 2rem;

      .checkbox_outer {
        display: flex;
        justify-content: space-between !important;
      }
    }

    .dark-checkbox {
      .check-box-white input:checked ~ .checkmark_product:after {
        display: block;
        border: 1px solid $primary-dark-color;
        border-width: 0 3px 3px 0;
      }
    }

    .login-button-outer {
      .login-submit-btn {
        gap: 15px;
        .login-submit-btn-info {
        }
        .forgot-password-container {
          display: flex;
          align-items: center;
          justify-content: center;
          color: #263238;
          cursor: pointer;
          .forgot-password-link-text{
            color: #00acba;
            font-weight: 500;
          }
        }
      }
    }
    .register-text-container {
      display: flex;
      align-items: center;
      margin: 10px 0;
      font-family: 'Titillium Web';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 26px;
      // justify-content: center;
      color: #263238;
      .register-text {
        font-size: 17px;
        line-height: 26px;
      }
      .register-here-text {
        color: $primary-color;
        font-weight: 600;
        font-size: 1.37rem;
        a {
          color: $primary-color;
        }
      }
    }
  }

  .left_top_img {
    position: absolute;
    // top: 0;
    // left: 0;
    top: 361px;
    // right: 0;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .right_bottom_img {
    position: absolute;
    bottom: 0;
    right: 0;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .bottom_shadow {
    padding: 20px 0;
    text-align: center;
    mix-blend-mode: multiply;
    @media screen and (max-width: 699px) {
      display: none;
    }
  }
}

@media (max-width: 1400px) {
  .login-page {
    background-position-y: 405px;
}}