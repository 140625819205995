.confirm-otp-page {
    background: $primary-dark-color;
    display: flex;
    justify-content: center;
    flex-direction: column;
  
    .confirm-otp-card {
      justify-content: center;
    }
    .confirm-otp-outer {
      min-width: 330px;
      max-width: 100%;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
    .confirm-otp-form-box {
      padding: 60px 50px;
      background: #ffffff;
      color: $secondary-dark-color;
      border-radius: 12px;
  
      @media screen and (max-width: 568px) {
        padding: 40px 50px;
      }
  
      .title {
        font-size: 1.1rem;
        font-weight: 600;
        text-align: start;
        padding: 0;
        width: 450px;
        @media (min-width: 768px) and (max-width: 1023px) {
          width: 420px;
        }
        @media (max-width: 767px) {
          width: 280px;
        }
      }
      .form-fields {
        // border: 1px solid red;
        min-width: 450px;
        .form-fields-input-btn{
          margin: 30px 0px;
          div{
              display: flex;
              align-items: center;
              justify-content: space-between;
              input {
                outline: none;
              }
              input:focus {
                border: 3px solid $primary-orange-color !important;
              }      
          }
        }
        .checkbox_outer {
          display: flex;
          justify-content: space-between !important;
        }
        @media (min-width: 768px) and (max-width: 1023px) {
          min-width: 420px;
        }
        @media (max-width: 767px) {
          min-width: 280px;
        }
      }
      
      .back_link {
        min-width: 450px;
        padding-left: 0px !important;
        margin-bottom: 20px;
        font-weight: 500;
        span {
          img {
            height: 12px;
            width: 12px;
            border-radius: 50%;
            align-items: center;
            margin-right: 10px;
          }
        }

        text-align: start !important;
        @media (min-width: 768px) and (max-width: 1023px) {
          min-width: 420px;
        }
        @media (max-width: 767px) {
          min-width: 280px;
        }
      }

      .back_link:hover {
        cursor: pointer;
        color: $primary-color;
      }

      .dark-checkbox {
        .check-box-white input:checked ~ .checkmark_product:after {
          display: block;
          border: 1px solid $primary-dark-color;
          border-width: 0 3px 3px 0;
        }
      }
  
      .confirm-otp-button-outer {
        .confirm-otp-SubmitBtn {
          gap: 15px;
          .confirm-otp-SubmitBtnInfo {
          }
        }
      }
    }
  
    .left_top_img {
      position: absolute;
      top: 0;
      left: 0;
  
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  
    .right_bottom_img {
      position: absolute;
      bottom: 0;
      right: 0;
  
      @media screen and (max-width: 1024px) {
        display: none;
      }
    }
  
    .bottom_shadow {
      padding: 30px 0;
      text-align: center;
      mix-blend-mode: multiply;
      @media screen and (max-width: 699px) {
        display: none;
      }
    }
  }
  