.send-otp-page {
  background: #02223a;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;

  .send-otp-card {
    justify-content: center;
    .logo-box {
      padding: 120px 0 50px 0;
    }
  }
  .send-otp_outer {
    width: 600px;
    max-width: 100%;
    padding-left: 0px !important;
    padding-right: 0px !important;
    @media screen and (min-width: 568px) {
      // min-width:700px;
      // padding: 30px 19px;
    }
    @media screen and (min-width: 768px) {
      min-width: 650px;
      // padding: 30px 19px;
    }
  }
  .send-otp-form-box {
    padding: 80px;
    background: #ffffff;
    color: #263238;
    border-radius: 12px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 568px) {
      padding: 80px 40px;
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }
    .form-fields {
      .form-fields-radio-btn {
        margin: 20px 0px;
        .css-qfz70r-MuiFormGroup-root {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .checkbox_outer {
        display: flex;
        justify-content: space-between !important;
      }
    }

    .dark-checkbox {
      .check-box-white input:checked ~ .checkmark_product:after {
        display: block;
        border: 1px solid $primary-dark-color;
        border-width: 0 3px 3px 0;
      }
    }

    .send-otp-button-outer {
      .send-otp-SubmitBtn {
        gap: 15px;
        .send-otp-SubmitBtnInfo {
        }
      }
    }
  }

  .left_top_img {
    position: absolute;
    top: 0;
    left: 0;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .right_bottom_img {
    position: absolute;
    bottom: 0;
    right: 0;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  .bottom_shadow {
    padding: 30px 0;
    text-align: center;
    mix-blend-mode: multiply;
    @media screen and (max-width: 699px) {
      display: none;
    }
  }
}
