// Titillium Web Fonts
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;600;700;900&display=swap');

@import './variables.scss';
@import './typography.scss';
@import './global.scss';

@import './core/input.scss';
@import './core/check-input.scss';

@import './pages/components/header.scss';
@import './pages/components/footer.scss';
@import './pages/components/search-bar.scss';

@import './pages/login.scss';
@import './pages/signup.scss';
@import './pages/forgot.scss';
@import './pages/sendotp.scss';
@import './pages/confirmotp.scss';
@import './pages/dashboard.scss';

@import './components/chat-floating-icon.scss';
@import './components/tabs-bar.scss';
@import './components/auto-complete.scss';

@import './temp/pages/contact.scss';
@import './temp/pages/contact_profile.scss';
@import './temp/pages/favorites.scss';
@import './temp/pages/general_info.scss';
@import './temp/pages/managing_orders.scss';
@import './temp/pages/patient.scss';
@import './temp/pages/privacy_policy.scss';
@import './temp/pages/prescription-ordering.scss';
@import './temp/pages/product.scss';
@import './temp/pages/settings.scss';

// @import './temp/popup/AddMedication.scss';
@import './temp/popup/card.scss';
@import './temp/popup/popup.scss';
@import './temp/popup/newPatient.scss';
@import './temp/table.scss';

span.profile_icon {
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 27px;
  width: 27px;
  border-radius: 50%;
  background-color: #00acba;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#basic-menu {
  .MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper {
    left: unset !important;
    top: 112px !important;
    right: 50px !important;
    width: 200px !important;
  }
}

.dashboard_dropdown {
  width: 100%;
  align-items: center;
  margin: 5px 5px;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: transparent !important;
  color: inherit !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root {
  height: auto !important;
}
.MuiAutocomplete-hasClearIcon.css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root {
  height: auto !important;
}

.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root, .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-qm9mjt-MuiAutocomplete-root .MuiOutlinedInput-root {
  height: auto!important;
}

// ---------------------------------------------------//
// overridden mui input classes for form inputs globally
.MuiOutlinedInput-root {
  width: 100%;
  height: 45px !important;
  @media only screen and (max-width: 668px) {
    margin: auto;
    // width: 70%;
    height: 45px !important;
  }
}

.MuiInputLabel-root {
  top: -5px !important;
  z-index: 0;
}

.Mui-disabled { 
  height: auto !important;
}
// ---------------------------------------------------//

// to customize react-datepicker override below classes
.react-datepicker-popper {
  z-index: 2 !important;
  // background-color: #ffffff !important;
  opacity: none !important;

  .react-datepicker {
    // background-color: #ffffff !important;
    border: 1px solid $primary-color !important;
    
    .react-datepicker__month-container {
      // background-color: #ffffff !important;
      
      .react-datepicker__header{
        background-color: #ffffff;
      
        .react-datepicker__header__dropdown, .react-datepicker__header__dropdown--select {

          .react-datepicker__month-dropdown-container, .react-datepicker__month-dropdown-container--select {

            .react-datepicker__month-select{
              border: 1.5px solid $primary-color !important;
              border-radius: 5px;
            }
          }

          .react-datepicker__year-dropdown-container, .react-datepicker__year-dropdown-container--select {

            .react-datepicker__year-select {
              border: 1.5px solid $primary-color !important;
              border-radius: 5px;
            }
          }

        }
      
      }
      
      .react-datepicker__month{

      }
    }
  }


  .react-datepicker {
    background-color: #ffffff !important;
    border: 1px solid $primary-color !important;
    
    .react-datepicker__month-container {
      background-color: #ffffff !important;
      
      .react-datepicker__header{
        background-color: #ffffff;
      
        .react-datepicker__header__dropdown, .react-datepicker__header__dropdown--select {

          .react-datepicker__month-dropdown-container, .react-datepicker__month-dropdown-container--select {

            .react-datepicker__month-select{
              border: 1.5px solid $primary-color !important;
              border-radius: 5px;
            }
          }

          .react-datepicker__year-dropdown-container, .react-datepicker__year-dropdown-container--select {

            .react-datepicker__year-select {
              border: 1.5px solid $primary-color !important;
              border-radius: 5px;
            }
          }

        }
      
      }
      
      .react-datepicker__month{

      }
    }
  }

  .react-datepicker__triangle{
    // color: $primary-color !important;

  }
}