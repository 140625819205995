.footer {
  width: auto;
  background-color: rgb(2, 35, 57);
  @media screen and (max-width: 1300px) {
    padding: 0 20px;
  }
  .footerContainer {
    background-color: rgb(2, 35, 57);
    padding: 2em 0em;
    .footer_desc {
      border-top: 1px solid #00acba;
      .footer_desc_text {
        margin-top: 20px;
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        color: #fff;
      }
    }

    .footer_Copyright {
      margin: 15px 0px;

      .footer_Copyright_text {
        color: #5592ff;
        text-align: center;
        font-size: 15px;
        font-weight: 600;
        padding-left: 10px;
        span {
          position: relative;
          font-size: 19px;
          align-self: center;
          left: -1px;
          top: 3px;
        }
      }
    }
    .footer_privacy {
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      ul {
        display: block;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
      }
      .footer_privacy_list {
        display: flex;
        flex-direction: row;
        align-items: center !important;
        justify-content: center !important;
        list-style-type: none;
        font-size: 15px;
        font-weight: 500;
        text-align: center;
        margin: 0;

        li {
          margin: 0;
          padding: 0px 7px;
        }
        li:hover {
          color: #5592ff;
          cursor: pointer;
        }
      }
    }
  }
}
