.dashboard-page {
  h5.same-typography-final {
    color: $secondary-grey-color;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;

    @media only screen and (max-width: 568px) {
      font-size: 16px;
    }

    @media only screen and (min-width: 1200px) and (max-width: 1440px) {
      font-size: 14px;
    }
  }

  .main-content-wrap {
    padding: 10px;
    background: #f7f7f7;
    border-radius: 12px;
    box-shadow: 0px 0px 100px -20px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 9;

    @media only screen and (max-width: 991px) {
      padding: 7px 7px 20px;
      margin-bottom: 15px;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #fafafa;
      border-radius: 30px;
    }

    .dashboard_col_layout {
      display: flex;
      flex-direction: column;

      .dashboardFlex {
        flex: 1;
        @media only screen and (max-width: 1100px) {
          flex-direction: column !important;
        }
      }
    }

    #dashboard_set {
      @media only screen and (max-width: 1400px) {
        flex-direction: column !important;
      }
    }

    .view-all-link {
      a {
        color: rgba(38, 50, 56, 0.33);
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;
      }
    }

    .card-sample-block {
      padding: 20px 10px;
      border-radius: 12px;
      background: #fff;
      height: 100%;
      width: 100%;

      .patient_profile {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 35px;
        max-width: 432px;

        @media only screen and (max-width: 568px) {
          flex-direction: column;
          padding: 10px;
        }

        .card-user-profile {
          position: relative;
          padding-top: 100%;
          width: 135px;
          height: 135px;
          background-color: #f7f7f7;
          background-image: url('../../assets/icons/dummy_user.png');

          .dashboard_camera {
            width: 40px;
            height: 40px;
            background-color: #fff;
            border-radius: 50%;
            cursor: pointer;
            position: absolute;
            bottom: 0;
            right: 0;
          }

          @media only screen and (min-width: 1200px) and (max-width: 1440px) {
            width: 100px;
            height: 100%;
          }

          &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-image: url('../../assets/icons/profile-bdr.svg');
          }
        }

        .card-user-profile,
        .card-user-profile::before {
          background-position: center !important;
          background-repeat: no-repeat !important;
          background-size: contain !important;
          border-radius: 50%;
          object-fit: contain;
        }

        .card-user-desc {
          padding-left: 20px;
          width: 100%;
          @media only screen and (max-width: 568px) {
            padding-left: 0px;
          }
          .user_heading {
            word-break: break-all;
            height: 65px;
            overflow: hidden;
          }
          p {
            color: $secondary-grey-color;
            word-break: break-all;
            // height: 70px;
            overflow: hidden;
            span {
              font-weight: 600;
            }
          }

          .small {
            font-size: 18px;
          }
          .productTour {
            font-size: 18px;
            font-weight: 400;
            margin-top: 7px;
            cursor: pointer;
            color: #00acba;
            text-decoration: underline;
          }
        }
      }

      .card_sample_in {
        height: 100% !important;

        @media only screen and (max-width: 991px) {
          flex-direction: column !important;
        }
      }

      .patient-invoice-block {
        height: 100%;

        .patient-invoice-heading {
          display: flex;
          align-items: center;
          @media only screen and (max-width: 576px) {
            flex-direction: column;
            align-items: flex-end;
          }

          h4 {
            font-family: 'Titillium Web';
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 43px;
            color: rgba(38, 50, 56, 0.8);
            text-transform: capitalize;
            white-space: nowrap;
            text-align: center;
            line-height: 1;
          }
        }
        .patient-invoice-heading-text {
          width: 100%;
        }
        .pay-all-btn-box {
          // text-align: right;
          position: absolute;
          right: 30px;
          width: 121px;
          @media only screen and (max-width: 576px) {
            position: relative;
            margin-top: 10px;
            right: 0;
          }
          .pay-all-btn {
            color: #00acba;
            font-size: 18px;
            font-weight: 600;
            background-color: #fff;
            border: 2px solid #00acba;
            border-radius: 8px;
            box-shadow: none;
            height: 46px;
            width: 100%;
            text-transform: capitalize;
            cursor: pointer;
          }
        }
        .patient-unpaid-container {
          height: 100%;
          width: auto;
          min-width: 500px;
          .tab-btn {
            // width: 359px;
            min-width: 150px;
            height: 41px;
            flex-shrink: 0;
            border-radius: 15px 15px 0px 0px;
            background: #00acba;
            color: #fff;
            text-align: center;
            font-family: Titillium Web;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            margin-top: 20px;
            @media only screen and (max-width: 576px) {
              height: 50px;
              line-height: 1;
              padding: 10px;
            }
          }
          .tab-btn-nonactive {
            min-width: 150px;
            height: 41px;
            flex-shrink: 0;
            border-radius: 15px 15px 0px 0px;
            background: #f0f0f0;
            color: rgba(149, 149, 149, 0.8);
            text-align: center;
            font-family: Titillium Web;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            margin-top: 20px;
            @media only screen and (max-width: 576px) {
              height: 50px;
              line-height: 1;
              padding: 10px;
            }
          }
          .patient-medicine-container:last-child {
            border-right: none;
          }
          .patient-medicine-container {
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px dashed #00acba;
            margin-bottom: 15px;
            width: 100%;
            max-width: 500px !important;
            // border: 1px solid red;
            .medicine-image {
            }
            .medicine-desc {
              padding-right: 10px;
              min-width: 155px;
              .medicine-price {
                color: #f3893d;
                font-family: Titillium Web;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
              .medicine-name {
                color: $secondary-grey-color;
                font-family: Titillium Web;
                font-size: 19px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
              .prescriber-name {
                color: $secondary-grey-color;
                font-family: Titillium Web;
                font-size: 17px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                span {
                  color: $secondary-grey-color;
                  font-family: Titillium Web;
                  font-size: 17px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
              .issued-date {
                color: $secondary-grey-color;
                font-family: Titillium Web;
                font-size: 17px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                span {
                  color: $secondary-grey-color;
                  font-family: Titillium Web;
                  font-size: 17px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }
          .add_btn_out {
            display: flex;
            align-items: center;
            justify-content: right;
            .add_btn {
              background: #00acba;
              border: 2px solid #00acba;
              border-radius: 8px;
              height: 40px;
              width: 130px;
              font-family: 'Titillium Web';
              font-weight: 600;
              font-size: 16px;
              color: #ffffff;
              cursor: pointer;
            }
            .view-all-btn {
              color: #00acba;
              font-size: 18px;
              font-weight: 600;
              background-color: #fff;
              border: 2px solid #00acba;
              border-radius: 8px;
              box-shadow: none;
              height: 46px;
              text-transform: capitalize;
              cursor: pointer;
              margin-left: 7px;
            }
          }
          .unpaid_invoices_head{
            .MuiTabs-flexContainer{
              overflow-x: auto !important;
            }
          }
          .no-invoice-message {
            text-align: center;
            align-self: center;
            font-weight: 600;
            color: $primary-orange-color;
          }
        }
      }

      .patient-order-block {
        height: 100%;
        .patient-order-heading {
          display: flex;
          align-items: center;
          h4 {
            font-family: 'Titillium Web';
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 43px;
            color: rgba(38, 50, 56, 0.8);
            text-transform: capitalize;
            white-space: nowrap;
            text-align: center;
            line-height: 1;
          }
          .patient-order-heading-text {
            width: 100%;
            @media (min-width: 1024px) {
              h4 {
                font-size: 2.0em !important; 
               }
            }
            @media (min-width: 768px) and (max-width: 1023px) {
              h4 {
                font-size: 1.6em !important; 
               }
            }
            @media (max-width: 767px) {
              h4 {
               font-size: 1.3em !important; 
              }
            }
          }
        }
        .order-status-container {
          padding: 20px auto;
          margin: auto;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;

          .order-status-tabs-container {
            overflow-x: auto;
            min-width: 500px;
            width: 100%;
            overflow-x: auto;
            
          @media (min-width: 768px) and (max-width: 1023px) {
            min-width: 90%;            
          }
          @media (max-width: 767px) {
            min-width: 90%;
          }
            .tab-btn {
              color: $primary-color;
              text-align: center;
              font-family: Titillium Web;
              font-size: 1.25em;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              text-transform: capitalize;
              min-width: 10em;
              @media (min-width: 768px) and (max-width: 1023px) {
                font-size: 1.0em;
              }
              @media (max-width: 767px) {
                font-size: 0.8em;
                height: 2.0em;
                padding: 5px;
                min-width: 10em;
              }
            }
            .MuiTabs-scrollButtons {
              color: $primary-color;
              svg{
                height: 35px;
                width: 35px;
              }
            }
          }

          .order-status-tab {
            min-width: 90%;
            // min-height: 300px;
            width: 'auto';

            .order-processing-container {
              display: flex;
              align-items: center;
              align-self: center;
              justify-content: center;
              padding: 0px 1px;
              
              .inside-container {
                text-align: center;
                min-width: 70px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                // border: 1px solid red;
                .processing-text {
                  color: $primary-dark-color;
                  font-family: Titillium Web;
                  font-size: 1.2em;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  margin-bottom: 10px;
                  @media (min-width: 768px) and (max-width: 1023px) {
                    font-size: 1.0em;
                  }
                  @media (max-width: 767px) {
                    font-size: 0.7em;
                    min-width: 8em;
                  }
                }
                img {
                  bottom: 0;
                  height: 3em;
                  width: 4em;
                  align-self: center;
                  @media (min-width: 768px) and (max-width: 1023px) {
                    height: 2em;
                    width: 3em;
                    align-self: center;
                  }
                  @media (max-width: 767px) {
                    height: 1.6em;
                    width: 2.2em;
                    align-self: center;
                  }
               
                }
              }
            }
            .stepper-container {
              // padding: 0px 76px;
              display: 'flex';
              justify-content: 'space-between';
              min-width: 100%;
              .stepper-style{
                width: 100%;
                padding-left: 0;
                padding-right: 0;

                button{
                  height:2.0em;
                  width: 2.0em;
                  padding: 0;
                  @media (min-width: 768px) and (max-width: 1023px) {
                    height:1.8em;
                    width: 1.8em;
                  }
                  @media (max-width: 767px) {
                    height:1.6em;
                    width: 1.6em;
                  }
                }
                button:hover {
                  cursor: initial;
                }

                pointer-events: none !important;
              }

              @media only screen and (max-width: 576px) {
                padding: 0px 0px;
              }
            }
          }

          .order-status-rightbox {
            margin: auto;
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 500px;
            min-width: 300px;
            // max-width: 800px;
            // margin-right: 0;
            .order-status-rightbox-text {
              text-align: center;
              font-size: 1.1em;
              font-weight: 400;
              color: $secondary-grey-color;
              @media (min-width: 768px) and (max-width: 1023px) {
                font-size: 0.9em;
              }
              @media (max-width: 767px) {
                font-size: 0.8em;
              }
           
              // border: 1px solid red;
              // margin-bottom: 20px;
            }
            .right-box-button-container {
              text-align: center;
              padding: auto;
              .address-text-container {
                margin: auto;
                margin-bottom: 10px;
                max-width: 300px;
                min-width: 200px;
                @media (min-width: 768px) and (max-width: 1023px) {
                  font-size: 1.1em;
                  max-width: 240px;
                  min-width: 180px;
                }
                @media (max-width: 767px) {
                  font-size: 1em;
                  max-width: 200px;
                  min-width: 150px;
                }
                .address-heading {
                  text-align: start;
                  font-size: 1.1em;
                  font-weight: 700;
                  color: $secondary-grey-color;
                  @media (min-width: 768px) and (max-width: 1023px) {
                    font-size: 0.9em;
                  }
                  @media (max-width: 767px) {
                    font-size: 0.8em;
                  }
                }
                .address-text {
                  text-align: start;
                  font-size: 1.0em;
                  font-weight: 600;
                  color: $secondary-grey-color;
                  .address-row {
                    text-align: start !important;
                  }
                  @media (min-width: 768px) and (max-width: 1023px) {
                    font-size: 0.9em;
                  }
                  @media (max-width: 767px) {
                    font-size: 0.8em;
                  }
                }
              }
              .delivery-date-container {
                // border: 1px solid red;
                margin: auto;
                padding: auto;
                margin-bottom: 10px;
                max-width: 300px;
                min-width: 200px;
                display: flex;
                align-items: center;
                @media (min-width: 768px) and (max-width: 1023px) {
                  font-size: 1.1em;
                  max-width: 240px;
                  min-width: 180px;
                }
                @media (max-width: 767px) {
                  font-size: 1em;
                  max-width: 200px;
                  min-width: 150px;
                }
                .delivery-date-heading {
                  text-align: start;
                  font-size: 1.05em;
                  font-weight: 700;
                  color: $secondary-grey-color;
                  @media (min-width: 768px) and (max-width: 1023px) {
                    font-size: 0.9em;
                  }
                  @media (max-width: 767px) {
                    font-size: 0.8em;
                  }
                }
                .delivery-date {
                  text-align: start;
                  font-size: 1.0em;
                  font-weight: 600;
                  color: $secondary-grey-color;
                  // border: 1px solid red;
                  @media (min-width: 768px) and (max-width: 1023px) {
                    font-size: 0.9em;
                  }
                  @media (max-width: 767px) {
                    font-size: 0.8em;
                  }
                }
                .edit_icon {
                  display: inline-block;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 30px;
                  width: 30px;
                  // border: 1px solid red;
                  border-radius: 50%;
                  color: #ffffff;
                  cursor: pointer;
                }
              }
              .right-box-btn {
                color: $primary-color;
                font-size: 1.2em;
                font-weight: 600;
                background-color: #fff;
                border: 2px solid $primary-color;
                border-radius: 8px;
                box-shadow: none;
                height: 46px;
                max-width: 300px;
                min-width: 200px;
                width: 100%;
                text-transform: capitalize;
                cursor: pointer;
                @media (min-width: 768px) and (max-width: 1023px) {
                  font-size: 1.1em;
                  max-width: 240px;
                  min-width: 180px;
                }
                @media (max-width: 767px) {
                  font-size: 1em;
                  max-width: 200px;
                  min-width: 150px;
                }
              }

              // .btn-css{
              //   color: #f3893d !important;
              //   border: 2px solid #f3893d !important;

              // }


              .view-claim-form-link {
                font-size: 1.2em;
                color: $primary-color;
                text-align: center;
                text-decoration: underline;
                margin-top: 15px;
                font-weight: 600;
                @media (min-width: 768px) and (max-width: 1023px) {
                  font-size: 1.1em;
                }
                @media (max-width: 767px) {
                  font-size: 1em;
                }
              }
            }
          }
        }

        .no-orders-message {
          text-align: center;
          align-self: center;
          font-weight: 600;
          color: $primary-orange-color;
        }
        .patient-unpaid-container {          
          width: 100%;
          .patient-medicine-container:last-child {
            border-right: none;
          }
          .patient-medicine-container {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            border-right: 1px dashed #00acba;
            margin-bottom: 15px;
            width: 100%;
            min-height: 200px;
            max-height: 260px;
            @media (min-width: 768px) and (max-width: 1023px) {
              min-height: 180px;
              max-height: 220px;
            }
            @media (max-width: 767px) {
              min-height: 150px;
              max-height: 180px;
            }
         
            // border: 1px solid red;
            .medicine-image {
              height: 100%;
              width: 40%;
              text-align: center;
              img {
                height: 150px;
                aspect-ratio: 3/3;
                object-fit: contain;
                // mix-blend-mode: color-burn;
                @media (min-width: 768px) and (max-width: 1023px) {
                  height: 125px;
                }
                @media (max-width: 767px) {
                  height: 100px;
                }
              }
            }
            .medicine-desc {
              padding-right: 20px;
              width: 60%;
              // min-width: 300px !important;
              .medicine-price {
                color: #f3893d;
                font-family: Titillium Web;
                font-size: 1.7em;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                @media (min-width: 768px) and (max-width: 1023px) {
                  font-size: 1.3em;
                }
                @media (max-width: 767px) {
                  font-size: 1.2em;
                }
              }
              .medicine-name {
                color: $secondary-grey-color;
                font-family: Titillium Web;
                font-size: 1.1em;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                @media (min-width: 768px) and (max-width: 1023px) {
                  font-size: 0.9em;
                }
                @media (max-width: 767px) {
                  font-size: 0.8em;
                }
              }
              .prescriber-name {
                color: $secondary-grey-color;
                font-family: Titillium Web;
                font-size: 1.0em;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                @media (min-width: 768px) and (max-width: 1023px) {
                  font-size: 0.9em;
                }
                @media (max-width: 767px) {
                  font-size: 0.9em;
                }
                span {
                  color: $secondary-grey-color;
                  font-family: Titillium Web;
                  font-size: 1.0em;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  @media (min-width: 768px) and (max-width: 1023px) {
                    font-size: 0.9em;
                  }
                  @media (max-width: 767px) {
                    font-size: 0.9em;
                  }
                }
              }
              .issued-date {
                color: $secondary-grey-color;
                font-family: Titillium Web;
                font-size: 1.0em;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                @media (min-width: 768px) and (max-width: 1023px) {
                  font-size: 0.9em;
                }
                @media (max-width: 767px) {
                  font-size: 0.9em;
                }
                span {
                  color: $secondary-grey-color;
                  font-family: Titillium Web;
                  font-size: 17px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  @media (min-width: 768px) and (max-width: 1023px) {
                    font-size: 0.9em;
                  }
                  @media (max-width: 767px) {
                    font-size: 0.9em;
                  }
                }
              }
            }
          }
          .add_btn_out {
            display: flex;
            align-items: center;
            justify-content: right;
            .add_btn {
              background: #00acba;
              border: 2px solid #00acba;
              border-radius: 8px;
              height: 40px;
              width: 130px;
              font-family: 'Titillium Web';
              font-weight: 600;
              font-size: 16px;
              color: #ffffff;
              cursor: pointer;
            }
            .view-all-btn {
              color: #00acba;
              font-size: 18px;
              font-weight: 600;
              background-color: #fff;
              border: 2px solid #00acba;
              border-radius: 8px;
              box-shadow: none;
              height: 46px;
              text-transform: capitalize;
              cursor: pointer;
              margin-left: 7px;
            }
          }
          .unpaid_invoices_head{
            .MuiTabs-flexContainer{
              overflow-x: auto !important;
            }
          }
          .no-invoice-message {
            text-align: center;
            align-self: center;
            font-weight: 600;
            color: $primary-orange-color;
          }
        }
      }

      .divider-full-line {
        position: relative;
        top: 20px;
        height: 190px;
        width: 1.5px;
        margin-inline: 15px;
        background-color: rgba(38, 50, 56, 0.16);

        @media only screen and (max-width: 991px) {
          display: none;
        }
      }

      .used-products-spotlight {
        .most-used-heading {
          h3 {
            text-align: center;
            margin-left: 0;
          }

          .search_patient_btn {
            padding-top: 40px;
            margin-top: 15px;
            display: flex;
            align-items: center;

            button {
              @media only screen and (max-width: 991px) {
                margin: 15px 0;
              }
            }

            @media only screen and (max-width: 991px) {
              flex-direction: column;
              justify-content: center;
              padding-top: 0px;
              margin-top: 6px;
            }
          }
        }

        .most-used-products {
          .most-used-pdt-list {
            .product-short-img-block {
              margin-right: 0;

              // .product-short-img {
              //   width: 90px;

              //   @media only screen and (min-width: 1200px) and (max-width: 1300px) {
              //     width: 75px !important;
              //   }
              // }
            }

            h5.same-typography-final {
              margin-top: 5px;
              text-decoration: underline;
            }

            .product_spotlight {
              gap: 10px;

              @media only screen and (max-width: 1200px) {
                flex-wrap: wrap;
              }
            }
          }

          .view-all-link {
            text-align: center;

            a {
              color: $primary-color !important;
              font-size: 15px;
              font-style: italic;
              text-decoration: underline !important;
            }
          }
        }

        .searh-patient-inputfield {
          position: relative;
          padding-top: 1.5rem;

          label {
            left: 15px;
          }

          label[data-shrink='true'] {
            color: $primary-color;
          }

          input {
            padding-left: 15px;
          }

          .MuiAutocomplete-clearIndicator {
            margin-right: 8px;
          }

          .MuiAutocomplete-popupIndicator {
            opacity: 0;
          }

          .MuiInputBase-colorPrimary::after,
          .MuiInputBase-colorPrimary.Mui-focused::after {
            border-color: $primary-color;
          }

          .search-inputfield-btn {
            position: absolute;
            right: 8px;
            bottom: 10px;
            pointer-events: none;
          }
        }
      }

      .card-sample-counting {
        h1 {
          color: #f3893d;
          font-size: 65px;
          font-weight: 600;
          line-height: 1;
          cursor: pointer;
        }

        h4 {
          color: #263238;
          font-size: 20px;
          font-weight: 400;
          text-transform: capitalize;
          cursor: pointer;
        }

        &.prescriptions-counting {
          h1 {
            color: $primary-color;
          }
        }

        &.patients-counting {
          margin-top: -10px;
        }
      }

      .card-counting-divider {
        margin: 15px 0 8px;
        border: 1px dashed $primary-color;
      }

      .exclusive-product-slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        // .exclusive-product-slide-img {
        .product-slide-img {
          width: 210px;
          padding-top: 250px;
          background-image: url('../../assets/medicineBottle.svg');
          background-repeat: no-repeat !important;
          background-size: cover !important;
          background-position: center !important;
        }
        .product-slide-img1 {
          width: auto;
          padding-top: 185px;
          background-image: url('../../assets/icons/product_1.svg');
          background-repeat: no-repeat;
          background-size: contain;
        }
        .product-slide-img2 {
          width: auto;
          padding-top: 185px;
          background-image: url('../../assets/icons/product_2.svg');
          background-repeat: no-repeat;
          background-size: contain;
        }
        // }

        .exclusive-product-slide-desc {
          text-align: center;

          .exclusive-name-tag {
            display: inline-block;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            padding: 2px 22px;
            border-radius: 0px 8px;
            background-color: $primary-color;
            white-space: nowrap;
          }

          .exclusive-name-tag,
          h3 {
            text-transform: capitalize;
          }

          h3 {
            color: #515b60;
            font-size: 14px;
            line-height: 22px;
            margin-top: 8px;
            margin: 0;
            text-align: left;

            span {
              display: block;
              color: #263238;
              font-weight: 600;
            }

            span.name-highlight {
              font-family: 'Titillium Web';
              font-weight: 700;
              font-size: 22px;
              text-transform: capitalize;
              color: #515b60;
              margin: 10px 0;
            }
          }
          .sliderDescription {
            color: #515b60;
            font-size: 14px;
            line-height: 15px;
            margin-top: 8px;
            margin: 0;
            text-align: left;

            span {
              display: block;
              color: #263238;
              font-weight: 600;
            }

            span.name-highlight {
              font-family: 'Titillium Web';
              font-weight: 700;
              font-size: 22px;
              text-transform: capitalize;
              color: #515b60;
              margin: 10px 0;
            }
          }

          p {
            color: #263238;
            font-size: 14px;
            font-weight: 400;
            font-style: italic;
            margin-bottom: 5px;
            text-align: left;

            span {
              display: block;
              color: $primary-color;
              font-weight: 600;
              font-style: normal;
            }
          }

          .order-used-btn {
            margin: 10px 0px;
            padding-top: 12px;
          }

          ul {
            list-style: none;
            text-align: left;
            padding-left: 0;
            margin: 0;

            li {
              font-family: 'Titillium Web';
              font-weight: 600;
              font-size: 10px;
              text-transform: capitalize;
              color: rgba(38, 50, 56, 0.7);

              span {
                color: #00acba;
              }
            }
          }
        }
      }

      &.statics-ratio-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media only screen and (min-width: 1224px) and (max-width: 1272px) {
          padding: 16px 0px;
        }

        .stack-card-sample {
          gap: 10px;
          // @media only screen and (min-width:1224px) and (max-width: 1272px) {
          //   flex-direction: column;
          // }

          .card-counting-divider {
            // @media only screen and (min-width:1224px) and (max-width: 1272px) {
            //   display: none;
            // }
          }

          .patients-counting {
            h1 {
              font-size: 40px;
            }
            h4 {
              font-size: 20px;
            }
          }
        }

        .card-sample-counting {
          display: flex;
          flex-direction: column;

          h1,
          h4 {
            color: #676f74;
          }

          h1 {
            font-size: 30px;
            line-height: 32px;
            flex: 1;
          }

          h4 {
            font-size: 20px;
            font-weight: 600;
          }

          &.patients-counting {
            margin-top: 0;
          }
        }

        .card-counting-divider {
          margin: 15px 0 0;
          height: 55px;
        }
      }

      .important_message {
        color: #f3893d;
        background-color: inherit;
      }

      .start_prescription_btn_container {
        background-color: #f7f7f7;
      }

      .most_recent_prescription_container {
        background-color: #f7f7f7;
      }

      .start_prescription_btn {
        // padding-top: 45px;
        // padding-bottom: 44px;
        height: 80%;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 899px) {
          flex-direction: column;
          padding: 20px 0;
        }

        button {
          font-size: 16px;
          padding: 10px 15px;

          @media only screen and (max-width: 1500px) {
            white-space: nowrap;
            font-size: 15px !important;
          }

          @media only screen and (max-width: 1368px) {
            flex-direction: column;
            margin-left: 0;
            margin-top: 15px;
          }
        }
      }

      .view_all_info {
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 26px;
        text-decoration-line: underline;
        text-transform: capitalize;
        color: rgba(38, 50, 56, 0.33);
      }

      .recent-order-table-layout {
        table {
          thead {
            th {
              text-align: center;
              border: none;
            }
          }

          tbody {
            td {
              text-align: center;
            }

            .table_first {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }

    .card-sample-block-flex {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .right_arrow {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }

      .left_arrow {
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .main-content-wrap2 {
  

    @media only screen and (max-width: 991px) {
      padding: 7px 7px 20px;
      margin-bottom: 15px;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #fafafa;
      border-radius: 30px;
    }

    .dashboard_col_layout {
      display: flex;
      flex-direction: column;

      .dashboardFlex {
        flex: 1;
        @media only screen and (max-width: 1100px) {
          flex-direction: column !important;
        }
      }
    }

    #dashboard_set {
      @media only screen and (max-width: 1400px) {
        flex-direction: column !important;
      }
    }

    .view-all-link {
      a {
        color: rgba(38, 50, 56, 0.33);
        font-size: 16px;
        font-weight: 600;
        text-transform: capitalize;
      }
    }

    .card-sample-block {
      padding: 20px 10px;
      border-radius: 12px;
      background: #fff;
      height: 100%;
      width: 100%;

      .patient_profile {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 35px;
        max-width: 432px;

        @media only screen and (max-width: 568px) {
          flex-direction: column;
          padding: 10px;
        }

        .card-user-profile {
          position: relative;
          padding-top: 100%;
          width: 135px;
          height: 135px;
          background-color: #f7f7f7;
          background-image: url('../../assets/icons/dummy_user.png');

          .dashboard_camera {
            width: 40px;
            height: 40px;
            background-color: #fff;
            border-radius: 50%;
            cursor: pointer;
            position: absolute;
            bottom: 0;
            right: 0;
          }

          @media only screen and (min-width: 1200px) and (max-width: 1440px) {
            width: 100px;
            height: 100%;
          }

          &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-image: url('../../assets/icons/profile-bdr.svg');
          }
        }

        .card-user-profile,
        .card-user-profile::before {
          background-position: center !important;
          background-repeat: no-repeat !important;
          background-size: contain !important;
          border-radius: 50%;
          object-fit: contain;
        }

        .card-user-desc {
          padding-left: 20px;
          width: 100%;
          @media only screen and (max-width: 568px) {
            padding-left: 0px;
          }
          .user_heading {
            word-break: break-all;
            height: 65px;
            overflow: hidden;
          }
          p {
            color: $secondary-grey-color;
            word-break: break-all;
            // height: 70px;
            overflow: hidden;
            span {
              font-weight: 600;
            }
          }

          .small {
            font-size: 18px;
          }
          .productTour {
            font-size: 18px;
            font-weight: 400;
            margin-top: 7px;
            cursor: pointer;
            color: #00acba;
            text-decoration: underline;
          }
        }
      }

      .card_sample_in {
        height: 100% !important;

        @media only screen and (max-width: 991px) {
          flex-direction: column !important;
        }
      }

      .patient-invoice-block {
        height: 100%;

        .patient-invoice-heading {
          display: flex;
          align-items: center;
          @media only screen and (max-width: 576px) {
            flex-direction: column;
            align-items: flex-end;
          }

          h4 {
            font-family: 'Titillium Web';
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 43px;
            color: rgba(38, 50, 56, 0.8);
            text-transform: capitalize;
            white-space: nowrap;
            text-align: center;
            line-height: 1;
          }
        }
        .patient-invoice-heading-text {
          width: 100%;
        }
        .pay-all-btn-box {
          // text-align: right;
          position: absolute;
          right: 30px;
          width: 121px;
          @media only screen and (max-width: 576px) {
            position: relative;
            margin-top: 10px;
            right: 0;
          }
          .pay-all-btn {
            color: #00acba;
            font-size: 18px;
            font-weight: 600;
            background-color: #fff;
            border: 2px solid #00acba;
            border-radius: 8px;
            box-shadow: none;
            height: 46px;
            width: 100%;
            text-transform: capitalize;
            cursor: pointer;
          }
        }
        .patient-unpaid-container {
          height: 100%;
          width: auto;
          min-width: 500px;
          .tab-btn {
            // width: 359px;
            min-width: 150px;
            height: 41px;
            flex-shrink: 0;
            border-radius: 15px 15px 0px 0px;
            background: #00acba;
            color: #fff;
            text-align: center;
            font-family: Titillium Web;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            margin-top: 20px;
            @media only screen and (max-width: 576px) {
              height: 50px;
              line-height: 1;
              padding: 10px;
            }
          }
          .tab-btn-nonactive {
            min-width: 150px;
            height: 41px;
            flex-shrink: 0;
            border-radius: 15px 15px 0px 0px;
            background: #f0f0f0;
            color: rgba(149, 149, 149, 0.8);
            text-align: center;
            font-family: Titillium Web;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            margin-top: 20px;
            @media only screen and (max-width: 576px) {
              height: 50px;
              line-height: 1;
              padding: 10px;
            }
          }
          .patient-medicine-container:last-child {
            border-right: none;
          }
          .patient-medicine-container {
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px dashed #00acba;
            margin-bottom: 15px;
            width: 100%;
            max-width: 500px !important;
            // border: 1px solid red;
            .medicine-image {
            }
            .medicine-desc {
              padding-right: 10px;
              min-width: 155px;
              .medicine-price {
                color: #f3893d;
                font-family: Titillium Web;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
              .medicine-name {
                color: $secondary-grey-color;
                font-family: Titillium Web;
                font-size: 19px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
              }
              .prescriber-name {
                color: $secondary-grey-color;
                font-family: Titillium Web;
                font-size: 17px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                span {
                  color: $secondary-grey-color;
                  font-family: Titillium Web;
                  font-size: 17px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
              .issued-date {
                color: $secondary-grey-color;
                font-family: Titillium Web;
                font-size: 17px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                span {
                  color: $secondary-grey-color;
                  font-family: Titillium Web;
                  font-size: 17px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }
          .add_btn_out {
            display: flex;
            align-items: center;
            justify-content: right;
            .add_btn {
              background: #00acba;
              border: 2px solid #00acba;
              border-radius: 8px;
              height: 40px;
              width: 130px;
              font-family: 'Titillium Web';
              font-weight: 600;
              font-size: 16px;
              color: #ffffff;
              cursor: pointer;
            }
            .view-all-btn {
              color: #00acba;
              font-size: 18px;
              font-weight: 600;
              background-color: #fff;
              border: 2px solid #00acba;
              border-radius: 8px;
              box-shadow: none;
              height: 46px;
              text-transform: capitalize;
              cursor: pointer;
              margin-left: 7px;
            }
          }
          .unpaid_invoices_head{
            .MuiTabs-flexContainer{
              overflow-x: auto !important;
            }
          }
          .no-invoice-message {
            text-align: center;
            align-self: center;
            font-weight: 600;
            color: $primary-orange-color;
          }
        }
      }

      .patient-order-block {
        height: 100%;
        .patient-order-heading {
          display: flex;
          align-items: center;
          h4 {
            font-family: 'Titillium Web';
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 43px;
            color: rgba(38, 50, 56, 0.8);
            text-transform: capitalize;
            white-space: nowrap;
            text-align: center;
            line-height: 1;
          }
          .patient-order-heading-text {
            width: 100%;
            @media (min-width: 1024px) {
              h4 {
                font-size: 2.0em !important; 
               }
            }
            @media (min-width: 768px) and (max-width: 1023px) {
              h4 {
                font-size: 1.6em !important; 
               }
            }
            @media (max-width: 767px) {
              h4 {
               font-size: 1.3em !important; 
              }
            }
          }
        }
        .order-status-container {
          padding: 20px auto;
          margin: auto;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;

          .order-status-tabs-container {
            overflow-x: auto;
            min-width: 500px;
            width: 100%;
            overflow-x: auto;
            
          @media (min-width: 768px) and (max-width: 1023px) {
            min-width: 90%;            
          }
          @media (max-width: 767px) {
            min-width: 90%;
          }
            .tab-btn {
              color: $primary-color;
              text-align: center;
              font-family: Titillium Web;
              font-size: 1.25em;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              text-transform: capitalize;
              min-width: 10em;
              @media (min-width: 768px) and (max-width: 1023px) {
                font-size: 1.0em;
              }
              @media (max-width: 767px) {
                font-size: 0.8em;
                height: 2.0em;
                padding: 5px;
                min-width: 10em;
              }
            }
            .MuiTabs-scrollButtons {
              color: $primary-color;
              svg{
                height: 35px;
                width: 35px;
              }
            }
          }

          .order-status-tab {
            min-width: 90%;
            // min-height: 300px;
            width: 'auto';

            .order-processing-container {
              display: flex;
              align-items: center;
              align-self: center;
              justify-content: center;
              padding: 0px 1px;
              
              .inside-container {
                text-align: center;
                min-width: 70px;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                // border: 1px solid red;
                .processing-text {
                  color: $primary-dark-color;
                  font-family: Titillium Web;
                  font-size: 1.2em;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  margin-bottom: 10px;
                  @media (min-width: 768px) and (max-width: 1023px) {
                    font-size: 1.0em;
                  }
                  @media (max-width: 767px) {
                    font-size: 0.7em;
                    min-width: 8em;
                  }
                }
                img {
                  bottom: 0;
                  height: 3em;
                  width: 4em;
                  align-self: center;
                  @media (min-width: 768px) and (max-width: 1023px) {
                    height: 2em;
                    width: 3em;
                    align-self: center;
                  }
                  @media (max-width: 767px) {
                    height: 1.6em;
                    width: 2.2em;
                    align-self: center;
                  }
               
                }
              }
            }
            .stepper-container {
              // padding: 0px 76px;
              display: 'flex';
              justify-content: 'space-between';
              min-width: 100%;
              .stepper-style{
                width: 100%;
                padding-left: 0;
                padding-right: 0;

                button{
                  height:2.0em;
                  width: 2.0em;
                  padding: 0;
                  @media (min-width: 768px) and (max-width: 1023px) {
                    height:1.8em;
                    width: 1.8em;
                  }
                  @media (max-width: 767px) {
                    height:1.6em;
                    width: 1.6em;
                  }
                }
                button:hover {
                  cursor: initial;
                }

                pointer-events: none !important;
              }

              @media only screen and (max-width: 576px) {
                padding: 0px 0px;
              }
            }
          }

          .order-status-rightbox {
            margin: auto;
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 500px;
            min-width: 300px;
            // max-width: 800px;
            // margin-right: 0;
            .order-status-rightbox-text {
              text-align: center;
              font-size: 1.1em;
              font-weight: 400;
              color: $secondary-grey-color;
              @media (min-width: 768px) and (max-width: 1023px) {
                font-size: 0.9em;
              }
              @media (max-width: 767px) {
                font-size: 0.8em;
              }
           
              // border: 1px solid red;
              // margin-bottom: 20px;
            }
            .right-box-button-container {
              text-align: center;
              padding: auto;
              .address-text-container {
                margin: auto;
                margin-bottom: 10px;
                max-width: 300px;
                min-width: 200px;
                @media (min-width: 768px) and (max-width: 1023px) {
                  font-size: 1.1em;
                  max-width: 240px;
                  min-width: 180px;
                }
                @media (max-width: 767px) {
                  font-size: 1em;
                  max-width: 200px;
                  min-width: 150px;
                }
                .address-heading {
                  text-align: start;
                  font-size: 1.1em;
                  font-weight: 700;
                  color: $secondary-grey-color;
                  @media (min-width: 768px) and (max-width: 1023px) {
                    font-size: 0.9em;
                  }
                  @media (max-width: 767px) {
                    font-size: 0.8em;
                  }
                }
                .address-text {
                  text-align: start;
                  font-size: 1.0em;
                  font-weight: 600;
                  color: $secondary-grey-color;
                  .address-row {
                    text-align: start !important;
                  }
                  @media (min-width: 768px) and (max-width: 1023px) {
                    font-size: 0.9em;
                  }
                  @media (max-width: 767px) {
                    font-size: 0.8em;
                  }
                }
              }
              .delivery-date-container {
                // border: 1px solid red;
                margin: auto;
                padding: auto;
                margin-bottom: 10px;
                max-width: 300px;
                min-width: 200px;
                display: flex;
                align-items: center;
                @media (min-width: 768px) and (max-width: 1023px) {
                  font-size: 1.1em;
                  max-width: 240px;
                  min-width: 180px;
                }
                @media (max-width: 767px) {
                  font-size: 1em;
                  max-width: 200px;
                  min-width: 150px;
                }
                .delivery-date-heading {
                  text-align: start;
                  font-size: 1.05em;
                  font-weight: 700;
                  color: $secondary-grey-color;
                  @media (min-width: 768px) and (max-width: 1023px) {
                    font-size: 0.9em;
                  }
                  @media (max-width: 767px) {
                    font-size: 0.8em;
                  }
                }
                .delivery-date {
                  text-align: start;
                  font-size: 1.0em;
                  font-weight: 600;
                  color: $secondary-grey-color;
                  // border: 1px solid red;
                  @media (min-width: 768px) and (max-width: 1023px) {
                    font-size: 0.9em;
                  }
                  @media (max-width: 767px) {
                    font-size: 0.8em;
                  }
                }
                .edit_icon {
                  display: inline-block;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 30px;
                  width: 30px;
                  // border: 1px solid red;
                  border-radius: 50%;
                  color: #ffffff;
                  cursor: pointer;
                }
              }
              .right-box-btn {
                color: $primary-color;
                font-size: 1.2em;
                font-weight: 600;
                background-color: #fff;
                border: 2px solid $primary-color;
                border-radius: 8px;
                box-shadow: none;
                height: 46px;
                max-width: 300px;
                min-width: 200px;
                width: 100%;
                text-transform: capitalize;
                cursor: pointer;
                @media (min-width: 768px) and (max-width: 1023px) {
                  font-size: 1.1em;
                  max-width: 240px;
                  min-width: 180px;
                }
                @media (max-width: 767px) {
                  font-size: 1em;
                  max-width: 200px;
                  min-width: 150px;
                }
              }

              // .btn-css{
              //   color: #f3893d !important;
              //   border: 2px solid #f3893d !important;

              // }


              .view-claim-form-link {
                font-size: 1.2em;
                color: $primary-color;
                text-align: center;
                text-decoration: underline;
                margin-top: 15px;
                font-weight: 600;
                @media (min-width: 768px) and (max-width: 1023px) {
                  font-size: 1.1em;
                }
                @media (max-width: 767px) {
                  font-size: 1em;
                }
              }
            }
          }
        }

        .no-orders-message {
          text-align: center;
          align-self: center;
          font-weight: 600;
          color: $primary-orange-color;
        }
        .patient-unpaid-container {          
          width: 100%;
          .patient-medicine-container:last-child {
            border-right: none;
          }
          .patient-medicine-container {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            border-right: 1px dashed #00acba;
            margin-bottom: 15px;
            width: 100%;
            min-height: 200px;
            max-height: 260px;
            @media (min-width: 768px) and (max-width: 1023px) {
              min-height: 180px;
              max-height: 220px;
            }
            @media (max-width: 767px) {
              min-height: 150px;
              max-height: 180px;
            }
         
            // border: 1px solid red;
            .medicine-image {
              height: 100%;
              width: 40%;
              text-align: center;
              img {
                height: 150px;
                aspect-ratio: 3/3;
                object-fit: contain;
                // mix-blend-mode: color-burn;
                @media (min-width: 768px) and (max-width: 1023px) {
                  height: 125px;
                }
                @media (max-width: 767px) {
                  height: 100px;
                }
              }
            }
            .medicine-desc {
              padding-right: 20px;
              width: 60%;
              // min-width: 300px !important;
              .medicine-price {
                color: #f3893d;
                font-family: Titillium Web;
                font-size: 1.7em;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                @media (min-width: 768px) and (max-width: 1023px) {
                  font-size: 1.3em;
                }
                @media (max-width: 767px) {
                  font-size: 1.2em;
                }
              }
              .medicine-name {
                color: $secondary-grey-color;
                font-family: Titillium Web;
                font-size: 1.1em;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                @media (min-width: 768px) and (max-width: 1023px) {
                  font-size: 0.9em;
                }
                @media (max-width: 767px) {
                  font-size: 0.8em;
                }
              }
              .prescriber-name {
                color: $secondary-grey-color;
                font-family: Titillium Web;
                font-size: 1.0em;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                @media (min-width: 768px) and (max-width: 1023px) {
                  font-size: 0.9em;
                }
                @media (max-width: 767px) {
                  font-size: 0.9em;
                }
                span {
                  color: $secondary-grey-color;
                  font-family: Titillium Web;
                  font-size: 1.0em;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  @media (min-width: 768px) and (max-width: 1023px) {
                    font-size: 0.9em;
                  }
                  @media (max-width: 767px) {
                    font-size: 0.9em;
                  }
                }
              }
              .issued-date {
                color: $secondary-grey-color;
                font-family: Titillium Web;
                font-size: 1.0em;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                @media (min-width: 768px) and (max-width: 1023px) {
                  font-size: 0.9em;
                }
                @media (max-width: 767px) {
                  font-size: 0.9em;
                }
                span {
                  color: $secondary-grey-color;
                  font-family: Titillium Web;
                  font-size: 17px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  @media (min-width: 768px) and (max-width: 1023px) {
                    font-size: 0.9em;
                  }
                  @media (max-width: 767px) {
                    font-size: 0.9em;
                  }
                }
              }
            }
          }
          .add_btn_out {
            display: flex;
            align-items: center;
            justify-content: right;
            .add_btn {
              background: #00acba;
              border: 2px solid #00acba;
              border-radius: 8px;
              height: 40px;
              width: 130px;
              font-family: 'Titillium Web';
              font-weight: 600;
              font-size: 16px;
              color: #ffffff;
              cursor: pointer;
            }
            .view-all-btn {
              color: #00acba;
              font-size: 18px;
              font-weight: 600;
              background-color: #fff;
              border: 2px solid #00acba;
              border-radius: 8px;
              box-shadow: none;
              height: 46px;
              text-transform: capitalize;
              cursor: pointer;
              margin-left: 7px;
            }
          }
          .unpaid_invoices_head{
            .MuiTabs-flexContainer{
              overflow-x: auto !important;
            }
          }
          .no-invoice-message {
            text-align: center;
            align-self: center;
            font-weight: 600;
            color: $primary-orange-color;
          }
        }
      }

      .divider-full-line {
        position: relative;
        top: 20px;
        height: 190px;
        width: 1.5px;
        margin-inline: 15px;
        background-color: rgba(38, 50, 56, 0.16);

        @media only screen and (max-width: 991px) {
          display: none;
        }
      }

      .used-products-spotlight {
        .most-used-heading {
          h3 {
            text-align: center;
            margin-left: 0;
          }

          .search_patient_btn {
            padding-top: 40px;
            margin-top: 15px;
            display: flex;
            align-items: center;

            button {
              @media only screen and (max-width: 991px) {
                margin: 15px 0;
              }
            }

            @media only screen and (max-width: 991px) {
              flex-direction: column;
              justify-content: center;
              padding-top: 0px;
              margin-top: 6px;
            }
          }
        }

        .most-used-products {
          .most-used-pdt-list {
            .product-short-img-block {
              margin-right: 0;

              // .product-short-img {
              //   width: 90px;

              //   @media only screen and (min-width: 1200px) and (max-width: 1300px) {
              //     width: 75px !important;
              //   }
              // }
            }

            h5.same-typography-final {
              margin-top: 5px;
              text-decoration: underline;
            }

            .product_spotlight {
              gap: 10px;

              @media only screen and (max-width: 1200px) {
                flex-wrap: wrap;
              }
            }
          }

          .view-all-link {
            text-align: center;

            a {
              color: $primary-color !important;
              font-size: 15px;
              font-style: italic;
              text-decoration: underline !important;
            }
          }
        }

        .searh-patient-inputfield {
          position: relative;
          padding-top: 1.5rem;

          label {
            left: 15px;
          }

          label[data-shrink='true'] {
            color: $primary-color;
          }

          input {
            padding-left: 15px;
          }

          .MuiAutocomplete-clearIndicator {
            margin-right: 8px;
          }

          .MuiAutocomplete-popupIndicator {
            opacity: 0;
          }

          .MuiInputBase-colorPrimary::after,
          .MuiInputBase-colorPrimary.Mui-focused::after {
            border-color: $primary-color;
          }

          .search-inputfield-btn {
            position: absolute;
            right: 8px;
            bottom: 10px;
            pointer-events: none;
          }
        }
      }

      .card-sample-counting {
        h1 {
          color: #f3893d;
          font-size: 65px;
          font-weight: 600;
          line-height: 1;
          cursor: pointer;
        }

        h4 {
          color: #263238;
          font-size: 20px;
          font-weight: 400;
          text-transform: capitalize;
          cursor: pointer;
        }

        &.prescriptions-counting {
          h1 {
            color: $primary-color;
          }
        }

        &.patients-counting {
          margin-top: -10px;
        }
      }

      .card-counting-divider {
        margin: 15px 0 8px;
        border: 1px dashed $primary-color;
      }

      .exclusive-product-slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        // .exclusive-product-slide-img {
        .product-slide-img {
          width: 210px;
          padding-top: 250px;
          background-image: url('../../assets/medicineBottle.svg');
          background-repeat: no-repeat !important;
          background-size: cover !important;
          background-position: center !important;
        }
        .product-slide-img1 {
          width: auto;
          padding-top: 185px;
          background-image: url('../../assets/icons/product_1.svg');
          background-repeat: no-repeat;
          background-size: contain;
        }
        .product-slide-img2 {
          width: auto;
          padding-top: 185px;
          background-image: url('../../assets/icons/product_2.svg');
          background-repeat: no-repeat;
          background-size: contain;
        }
        // }

        .exclusive-product-slide-desc {
          text-align: center;

          .exclusive-name-tag {
            display: inline-block;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            padding: 2px 22px;
            border-radius: 0px 8px;
            background-color: $primary-color;
            white-space: nowrap;
          }

          .exclusive-name-tag,
          h3 {
            text-transform: capitalize;
          }

          h3 {
            color: #515b60;
            font-size: 14px;
            line-height: 22px;
            margin-top: 8px;
            margin: 0;
            text-align: left;

            span {
              display: block;
              color: #263238;
              font-weight: 600;
            }

            span.name-highlight {
              font-family: 'Titillium Web';
              font-weight: 700;
              font-size: 22px;
              text-transform: capitalize;
              color: #515b60;
              margin: 10px 0;
            }
          }
          .sliderDescription {
            color: #515b60;
            font-size: 14px;
            line-height: 15px;
            margin-top: 8px;
            margin: 0;
            text-align: left;

            span {
              display: block;
              color: #263238;
              font-weight: 600;
            }

            span.name-highlight {
              font-family: 'Titillium Web';
              font-weight: 700;
              font-size: 22px;
              text-transform: capitalize;
              color: #515b60;
              margin: 10px 0;
            }
          }

          p {
            color: #263238;
            font-size: 14px;
            font-weight: 400;
            font-style: italic;
            margin-bottom: 5px;
            text-align: left;

            span {
              display: block;
              color: $primary-color;
              font-weight: 600;
              font-style: normal;
            }
          }

          .order-used-btn {
            margin: 10px 0px;
            padding-top: 12px;
          }

          ul {
            list-style: none;
            text-align: left;
            padding-left: 0;
            margin: 0;

            li {
              font-family: 'Titillium Web';
              font-weight: 600;
              font-size: 10px;
              text-transform: capitalize;
              color: rgba(38, 50, 56, 0.7);

              span {
                color: #00acba;
              }
            }
          }
        }
      }

      &.statics-ratio-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @media only screen and (min-width: 1224px) and (max-width: 1272px) {
          padding: 16px 0px;
        }

        .stack-card-sample {
          gap: 10px;
          // @media only screen and (min-width:1224px) and (max-width: 1272px) {
          //   flex-direction: column;
          // }

          .card-counting-divider {
            // @media only screen and (min-width:1224px) and (max-width: 1272px) {
            //   display: none;
            // }
          }

          .patients-counting {
            h1 {
              font-size: 40px;
            }
            h4 {
              font-size: 20px;
            }
          }
        }

        .card-sample-counting {
          display: flex;
          flex-direction: column;

          h1,
          h4 {
            color: #676f74;
          }

          h1 {
            font-size: 30px;
            line-height: 32px;
            flex: 1;
          }

          h4 {
            font-size: 20px;
            font-weight: 600;
          }

          &.patients-counting {
            margin-top: 0;
          }
        }

        .card-counting-divider {
          margin: 15px 0 0;
          height: 55px;
        }
      }

      .important_message {
        color: #f3893d;
        background-color: inherit;
      }

      .start_prescription_btn_container {
        background-color: #f7f7f7;
      }

      .most_recent_prescription_container {
        background-color: #f7f7f7;
      }

      .start_prescription_btn {
        // padding-top: 45px;
        // padding-bottom: 44px;
        height: 80%;
        align-items: center;
        justify-content: center;

        @media only screen and (max-width: 899px) {
          flex-direction: column;
          padding: 20px 0;
        }

        button {
          font-size: 16px;
          padding: 10px 15px;

          @media only screen and (max-width: 1500px) {
            white-space: nowrap;
            font-size: 15px !important;
          }

          @media only screen and (max-width: 1368px) {
            flex-direction: column;
            margin-left: 0;
            margin-top: 15px;
          }
        }
      }

      .view_all_info {
        font-family: 'Titillium Web';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 26px;
        text-decoration-line: underline;
        text-transform: capitalize;
        color: rgba(38, 50, 56, 0.33);
      }

      .recent-order-table-layout {
        table {
          thead {
            th {
              text-align: center;
              border: none;
            }
          }

          tbody {
            td {
              text-align: center;
            }

            .table_first {
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }

    .card-sample-block-flex {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .right_arrow {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }

      .left_arrow {
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.order-used-btn {
  margin-top: 5px;
  // margin: 10px 0px;
}
.carasouselAlignment {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  .cardAlign {
    padding-bottom: 0px !important;
  }
  //  .css-ktslay-MuiButtonBase-root-MuiIconButton-root:hover {
  //   // background-color: rgba(0, 0, 0, 0.04);
  //   background-color: $secondary-grey-color;
  // }
  //  .css-ktslay-MuiButtonBase-root-MuiIconButton-root:hover {
  //   // background-color: rgba(0, 0, 0, 0.04);
  //   background-color: $secondary-grey-color;
  // }
}

.btn-css2{
  color: white !important;
  
  font-size: 1.2em;
  font-weight: 600 !important;
  background-color: $primary-color !important;
  
  border: 2px solid $primary-color !important;
  border-radius: 8px !important;
  box-shadow: none;

  width: 100% !important;
  text-transform: capitalize !important;
  cursor: pointer;
 
}

.fs{
  font-size: 1.14em !important;
}

 
.f-size{
  font-size: 0.875rem !important;
}

.bt-flex{
display: flex;
justify-content: center;
align-items: center;
text-align: center;
}

.btn-css3{
  color: $primary-color !important;
  
  font-size: 1.2em;
  font-weight: 600 !important;
  background-color: white !important;
  
  border: 2px solid $primary-color !important;
  border-radius: 8px !important;
  box-shadow: none;

  width: 40% !important;
  text-transform: capitalize !important;
  cursor: pointer;
 
}

.Enroll-box-container{

  .right-box-btn-2 {
    color: #f3893d;
    font-size: 1em;
    font-weight: 600;
    background-color: none !important;
    border: 2px solid #f3893d;
    border-radius: 8px;
    box-shadow: none;
    height: 45px;
    max-width: 120px;
    min-width: 120px;
    width: 100%;
    text-transform: capitalize;
    cursor: pointer;
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 1em;
      max-width: 120px;
      min-width: 120px;
    }
    @media (max-width: 767px) {
      font-size: 1em;
      max-width: 120px;
      min-width: 120px;
    }
  }

  .request-right-box-btn-2 {
    color:#f3893d;
    font-size: 1em;
    font-weight: 600;
    background-color: none !important;
    border: 2px solid #f3893d;
    border-radius: 8px;
    box-shadow: none;
    height: 45px;
    max-width: 150px;
    min-width: 150px;
    width: 100%;
    text-transform: capitalize;
    cursor: pointer;
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 1em;
      max-width: 150px;
      min-width: 150px;
    }
    @media (max-width: 767px) {
      font-size: 1em;
      max-width: 150px;
      min-width: 150px;
    }
  }


}

.Un-Enroll-box-container{

  .right-box-btn-2 {
    color: #f3893d;
    font-size: 1em;
    font-weight: 600;
    background-color: none !important;
    border: 2px solid #f3893d;
    border-radius: 8px;
    box-shadow: none;
    height: 45px;
    max-width: 120px;
    min-width: 120px;
    width: 100%;
    text-transform: capitalize;
    cursor: pointer;
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 1em;
      max-width: 120px;
      min-width: 120px;
    }
    @media (max-width: 767px) {
      font-size: 1em;
      max-width: 120px;
      min-width: 120px;
    }
  }
}

.td-text{
  line-break: auto !important;
  white-space: normal !important;
}

.max-w{
  max-width: 100% !important;
}

.inline-container{
  display: flex !important;
  justify-content: space-between !important;

  @media (max-width: 480px) {
    display: grid !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

.text-end{
text-align: end !important;
}

.info.active {
  font-weight: bold;
  color: #f3893d !important;
}



.maininfo{
 
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  
  box-shadow: -1px 0px 0px 1px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 11;
  width: 100%;
  background: white;}
.maininfo2{
 
  border-radius: 12px;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 9;
}

@media (max-width: 900px) {
  .hid-tab{
    display: none !important;
  }
}

.color-orange{
  color: #f3893d !important;
}

.icon-back:hover{
  background: transparent !important;
  color: #00acba !important;
  border: 2px solid #00acba;
}

@media (min-width: 370px) {
  .ws{
    white-space: nowrap !important;
  }
}

@media (max-width: 370px) {
  .ws{
    white-space: normal !important;
  }
}
